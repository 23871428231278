<template>
   <div>
      <div class="container">
         <div class="row justify-content-md-center mt-4 mb-2 pb-row">
            <div class="col-12 col-lg-8 text-center login-signup">
               <h4>Payment</h4>
            </div>
         </div>
         <div class="row justify-content-md-center mt-4 mb-2 pb-row">
            <div class="col-12 col-lg-8 text-center pb-5 login-signup-form">
               <div class="row" style="justify-content: space-around;">
                  <div class="col-12 col-lg-8 text-left pb-4 mg-auto">

                     <form v-if="!isInvoiceOpen">

                        <div class="form-group" v-if="!isShowingOffer">
                           <label for="exampleFormControlSelect1">Select Set Size</label>
                           <select class="form-control" id="plan-select-drop" v-model="selected" v-if="plans != null">
                              <option v-for="(plan, id) in plans" v-bind:key="id" v-bind:value="id">
                                 {{ `${plan.name} plan / $${plan.cost}` }}
                              </option>
                           </select>
                           <!-- <div class="mt-1">
                              <a href="signup-plan.html#" data-toggle="modal" data-target="#pricing-modal">view plans</a>
                           </div> -->
                        </div>

                        <hr class="mt-4 mb-3" v-if="!isShowingOffer">

                        <h6>Pay With</h6>
                        <div class="form-check form-check-inline">
                           <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="credit-card-pay" checked v-model="chosenPaymentOption">
                           <label class="form-check-label" for="inlineRadio1">Credit Card</label>
                        </div>
                        <div class="form-check form-check-inline opacity-50">
                           <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="crypto-pay" v-model="chosenPaymentOption">
                           <label class="form-check-label" for="inlineRadio2">Crypto</label>
                        </div>

                        <hr class="mt-4 mb-3" v-if="!isShowingOffer">
                        <form class="form mt-3" v-if="!isShowingOffer">
                           <div class="promo-code-1">
                              <div class="form-group">
                                 <label for="promoCode">Promo Code</label>
                                 <input type="text" class="form-control" id="promoCode" placeholder="Promo Code" v-model="promoCode">
                                 <div class="justify-content-md-center mt-2">
                                    
                                    <button class="btn btn-orange btn-lg btn-block mt-1" @click="$event.preventDefault(); preValidateOrder();">Apply</button>

                                 </div>
                                 <div class="col" v-if="preCheckError">
                                    <p class="text-danger mt-2">{{preCheckError}}</p>
                                 </div>
                                 <div class="col" v-if="preCheckSuccess">
                                    <p class="text-success mt-2">{{preCheckSuccess}}</p>
                                 </div>
                              </div>
                           </div>
                        </form>
                        <hr class="mt-4 mb-3">

                        <div class="credit-card-pay select col" v-if="chosenPaymentOption === 'credit-card-pay'">
                           <div class="row pb-4">
                              <img src="@/assets/img/stripe_logo.svg" alt="logo" width="200">
                           </div>
                           <div class="row pb-4">
                              <img src="@/assets/img/bitpay-transfer.webp" alt="redirect symbol" width="110">
                           </div>
                           <p class="pb-4">After clicking “Checkout”, you will be redirected to Stripe to complete your purchase securely.</p>
                        </div><!--END Credit card pay-->

                        <div class="crypto-pay select" style="display: block !important" v-else>
                           <div class="row pb-4">
                              <img src="@/assets/img/bitpay-logo-blue.jpg" alt="plogo" width="110">
                           </div>
                           <div class="row pb-4">
                              <img src="@/assets/img/bitpay-transfer.webp" alt="redirect symbol" width="110">
                           </div>
                           <div class="form-group mb-5" v-if="isShowingOffer">
                             <label for="exampleFormControlSelect1">Select Number of Years</label>
                             <select class="form-control" id="plan-select-drop" v-model="cryptoYearsPrepaid">
                               <option value="2">2 years / $198</option>
                               <option value="3">3 years / $297</option>
                               <option value="4">4 years / $369</option>
                               <option value="5">5 years / $495</option>
                             </select>
                           </div>                           
                                 <p class="pb-4">After clicking “Checkout”, you will be redirected to BitPay to complete your purchase securely.</p>
                        </div>

                        <div class="row justify-content-md-center" v-if="netAmount && !isShowingOffer">
                           Net amount: ${{netAmount ? netAmount.toString() : "N/A"}}
                        </div>
                        <button 
                           v-if="!isShowingOffer"
                           class="btn btn-orange btn-lg btn-block login-btn mt-5"
                           @click="$event.preventDefault(); checkout()" >
                           Checkout
                        </button>

                        <div class="row justify-content-md-center" v-if="isShowingOffer">
                           <div class="total">
                             <div class="mt-5 mb-4">
                               <table class="table">
                                 <tbody>
                                   <tr v-if="$store.state.accountData['offer_first_year_cost'] !== $store.state.accountData['offer_subsequent_year_cost']">
                                     <th scope="row">Current Credit:</th>
                                     <td>-${{$store.state.accountData['offer_subsequent_year_cost'] - $store.state.accountData['offer_first_year_cost']}}</td>
                                   </tr>
                                   <tr>
                                     <th scope="row">Total Annual Plan:</th>
                                     <td>${{$store.state.accountData['offer_subsequent_year_cost']}}</td>
                                   </tr>
                                   <tr v-if="!isCryptoPayment">
                                     <th scope="row">Total Amount Due:</th>
                                     <td>${{$store.state.accountData['offer_first_year_cost']}}</td>
                                   </tr>
                                   <tr v-if="isCryptoPayment">
                                     <th scope="row">Total Amount Due:</th>
                                     <td>${{$store.state.accountData['offer_subsequent_year_cost'] * (cryptoYearsPrepaid - 1) + $store.state.accountData['offer_first_year_cost']}}<span> ({{cryptoYearsPrepaid}} years prepaid)</span></td>
                                   </tr>
                                 </tbody>
                               </table>
                               <em class="text-muted" v-if="!isCryptoPayment">Note: Each additional year your card will be charged ${{$store.state.accountData['offer_subsequent_year_cost']}}</em>


                             </div>
                           </div>
                        </div>
                        <button 
                           v-if="isShowingOffer"
                           class="btn btn-orange btn-lg btn-block login-btn mt-5"
                           @click="$event.preventDefault(); checkout()" >
                           Checkout
                        </button>



                        <div class="text-danger">{{checkoutErrorMsg}}</div>
                     </form>





                     <div v-else-if="shouldDisplayWaitingPage">

                        <div v-if="isInvoiceDiscarded">
                           <h6>
                              Unfortunately, this payment attempt was canceled or abandoned.
                           </h6>
                           <button 
                              class="btn btn-orange btn-lg btn-block login-btn mt-5"
                              @click="$event.preventDefault(); continueAbandoned()"
                           >
                              Try Again
                           </button>

                        </div>
                        
                        
                        <div v-else>
                           <h6>
                              Waiting for a pending payment to complete
                           </h6>
                           <p>
                              You can refresh the page to see updated status. When your pending payment completes, your credits will be shown in your <a href="account">account page</a>.
                           </p>
                           <hr class="mt-4 mb-3">
                           <h6 v-if="paymentStatus !== ''">
                              Status: <b>{{ paymentStatus }}</b>
                           </h6>
                           <h6 v-if="chosenCheckoutProduct !== ''">
                              Product: <b>{{ chosenCheckoutProduct }}-slice</b>
                           </h6>

                           <div>
                           <button
                              class="btn btn-primary btn-lg btn-inline login-btn mt-5"
                              style="width:48%"
                              @click="$event.preventDefault(); replace('https://app.xecret.io');"
                              v-if="canReopenPayment"
                           >
                              Home
                           </button>
                           <span style="width:2%">&nbsp;</span>
                           <button
                              class="btn btn-outline-primary btn-lg btn-inline login-btn mt-5"
                              style="width:48%"
                              @click="$event.preventDefault(); replace(paymentRedirectUrl);"
                              v-if="canReopenPayment"
                           >
                              Re-Open Payment
                           </button>
                           </div>
                           <button
                              class="btn btn-danger btn-lg btn-block login-btn mt-5"
                              @click="$event.preventDefault(); attemptCancelPayment()"
                              v-if="canCancelPayment"
                           >
                              Cancel Payment
                           </button>
                        </div>

                     </div>




                     <div v-else>
                        <h6>
                           Your payment is complete! Click to continue.
                        </h6>
                        <h6 v-if="referralMessage !== ''">
                           {{ referralMessage }}
                        </h6>
                        <button 
                           class="btn btn-orange btn-lg btn-block login-btn mt-5"
                           @click="$event.preventDefault(); continueSettled()"
                        >
                           Continue
                        </button>

                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>


   </div>
</template>

<script>

const paymentChoiceCreditCard = "credit-card-pay";
const paymentChoiceCrypto = "crypto-pay";

export default {
   data() {
      return {
         chosenPaymentOption: paymentChoiceCreditCard,
         plans: null,
         selected: 3,
         paymentRedirectUrl: "",
         paymentStatus: "",
         chosenCheckoutProduct: "",
         checkoutErrorMsg: "",
         canCancelPayment: false,
         currentInvoiceId: "",
         cryptoYearsPrepaid: 2,
         isInvoiceSettled: false,
         isInvoiceDiscarded: false,
         isShowingAdvanced: false,
         isShowingOffer: false,
         netAmount: "",
         promoCode: "",
         preCheckError: "",
         preCheckSuccess: "",
         referralMessage: "",
      };
   },
   computed: {
      isCryptoPayment: function() { return this.chosenPaymentOption === paymentChoiceCrypto; },
      isCreditCardPayment: function() { return this.chosenPaymentOption === paymentChoiceCreditCard; },
      isInvoiceOpen: function() { return Boolean(this.currentInvoiceId); },
      shouldDisplayWaitingPage: function() { return this.isInvoiceOpen && !this.isInvoiceSettled; },
      canReopenPayment: function() { return this.isCryptoPayment && this.paymentRedirectUrl != ""; }
   },
   mounted() {
      window.gtag("event", "PaymentPage");
      if (this.$store.state.purchaseSliceCount) {
         let purchaseSliceCountTime = this.$store.state.purchaseSliceCountTime;
         let minutesSinceSet = ((new Date()).getTime() - purchaseSliceCountTime) / 1000 / 60 / 30;
         if (minutesSinceSet < 30) {
            this.$store.commit("setDidPromptPurchaseOptions", true);
            this.chosenCheckoutProduct = parseInt(this.$store.state.purchaseSliceCount);
            this.selected = this.chosenCheckoutProduct
            this.$store.commit("setPurchaseSliceCount", 0);
         }
      }

      let didSetReferralSlug = false;
      if (this.$store.state.referralSlug) {
         didSetReferralSlug = true;
         this.promoCode = this.$store.state.referralSlug;
         this.$store.commit("setReferralSlug", "");
         this.axios
            .post("/api/update-referral-promo-code", {promoCode: this.promoCode}, { withCredentials: true })
            .then((response) => {
               console.log(response); // no action
            })
            .catch((error) => {
               console.error(error);
            })
      }

      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.$store.commit("setAccountData", response.data);
         })
         .catch((error) => console.error(error));
      if (this.$store.state.accountData.referred_by_promo_code) {
         if (!didSetReferralSlug) {
            this.promoCode = this.$store.state.accountData.referred_by_promo_code;
         }
      }

      if (this.$route.query.offer) {
         this.isShowingOffer = this.$route.query.offer;
      }

      if (this.$route.query.sliceCount) {
         this.selected = parseInt(this.$route.query.sliceCount);
      }

      // check for ongoing checkout session
      this.currentInvoiceId = this.$store.state.activeInvoiceId;
      console.log("loading invoice ID: " + this.currentInvoiceId);

      if (this.isInvoiceOpen)
      {
         console.log(this.currentInvoiceId);
         this.axios
            .post("/api/check-payment-status", {invoiceId: this.currentInvoiceId}, { withCredentials: true })
            .then((response) => {
               console.log(response.data)
               let isCrypto = response.data.is_crypto
               this.chosenPaymentOption = isCrypto ? paymentChoiceCrypto : paymentChoiceCreditCard;
               this.paymentStatus = response.data.status;
               if (isCrypto && (this.paymentStatus === "paid") || (this.paymentStatus === "confirmed")) {
                  this.paymentStatus = `transaction submitted (${this.paymentStatus}); waiting for blockchain`;
               }
               this.isInvoiceSettled = response.data.is_settled;
               this.isInvoiceDiscarded = response.data.is_discarded;
               this.paymentRedirectUrl = response.data.url;
               this.canCancelPayment = response.data.can_cancel_payment;
               this.referralMessage = response.data.referral_message;

               this.$store.commit("setAccountData", response.data.account_data);

               this.chosenCheckoutProduct = response.data.product;
            })
            .catch((error) => {
               console.error(error);
            })
      } // end if is invoice open

      // get the available plans from the server
      this.axios
         .get("/api/payment-plan", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.plans = {};
            for (let key in response.data) {
               let value = response.data[key];
               if (!this.$store.state.accountData['unlimited_subscription_slice_counts'].includes(parseInt(key))) {
                  this.plans[key] = value;
               }
            }
            console.log(this.plans);
         })
         .catch((error) => console.error(error));
   },
   methods: {
      preValidateOrder() {
         this.netAmount = "";
         this.checkoutErrorMsg = "";
         if (!this.promoCode) {
            this.preCheckError = "";
            this.preCheckSuccess = "";
            return;
         }
         let apiPath = "/api/validate-checkout";
         let sendParams = {
            slicePlan: this.isShowingOffer ? this.$store.state.accountData.offer_max_share_count : this.selected,
            referralSlug: this.promoCode,
            isCrypto: this.isCryptoPayment,
            isSubscription: this.isShowingOffer,
            subscriptionPrepaidYears: this.cryptoYearsPrepaid
         };
         this.axios
            .post(apiPath, sendParams, { withCredentials: true })
            .then((response) => {
               if (response.data) {
                  if (response.data.status === "success") {
                     this.preCheckError = "";
                     this.preCheckSuccess = "Successfully applied."
                     this.netAmount = response.data.net_amount
                  }
                  else {
                     this.preCheckError = response.data.status;
                     this.preCheckSuccess = "";
                  }
               }
               else {
                  this.preCheckError = "This coupon is not valid; please check your inputs and try again.";
                  this.preCheckSuccess = "";
               }
            })
            .catch((error) => {
               if (error.length && error.length < 100) {
                  this.preCheckError = error;
                  this.preCheckSuccess = "";
               }
               else {
                  this.preCheckError = "Error occurred validating coupon: please check your inputs and try again."
                  this.preCheckSuccess = "";
               }
               
               console.error(error);
            });
      },
      checkOffer() {

      },
      checkout() {
         const apiPath = (this.isCryptoPayment) ? 
          "/api/create-crypto-checkout" :
          "/api/create-card-checkout";
         console.log(apiPath);

         let promoCode = this.promoCode;

         const sendParams = {
            slicePlan: this.isShowingOffer ? this.$store.state.accountData.offer_max_share_count : this.selected,
            isSubscription: this.isShowingOffer,
            subscriptionPrepaidYears: this.cryptoYearsPrepaid,
            referralSlug: promoCode
         };
         console.log(sendParams);

         this.axios
            .post(apiPath, sendParams, { withCredentials: true })
            .then((response) => {
               if (response.data) {
                  if (response.data.status === "success") {
                     this.checkoutErrorMsg = "";
                  }
                  else {
                     this.checkoutErrorMsg = response.data.status;
                     return;
                  }
               }
               else {
                  this.checkoutErrorMsg = "Error occurred processing payment: please check your inputs and try again.";
                  return;
               }

               this.$store.commit("setReferralSlug", "");
               this.$store.commit("setActiveInvoiceId", response.data.id);
               window.location.assign(response.data.url);
            })
            .catch((error) => {
               if (error.length && error.length < 100) {
                  this.checkoutErrorMsg = error;
               }
               else {
                  this.checkoutErrorMsg = "Error occurred processing payment: please check your inputs and try again.";
               }
            });

      },
      continueSettled() {
         this.$store.commit("setActiveInvoiceId", "");
         window.location.assign("/");
      },
      continueAbandoned() {
         this.$store.commit("setActiveInvoiceId", "");
         window.location.assign("/payment");
      },
      attemptCancelPayment() {
         const invoiceIdToCancel = this.currentInvoiceId;
         this.$store.commit("setActiveInvoiceId", "");
         this.axios
            .post("/api/attempt-cancel-payment", {invoiceId: invoiceIdToCancel}, { withCredentials: true })
            .then(() => {
               window.location.assign("/payment");
            })
            .catch((error) => console.error(error));
      },
      replace(url) {
         window.location.assign(url);
      },
   }
}
</script>